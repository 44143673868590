import { auth } from '@/firebase'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('../views/MainView.vue')
		},
		{
			path: '/signup',
			name: 'signup',
			component: () => import('../views/SignUpView.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('../views/LoginView.vue'),
			meta: {
				requiresAnonymous: true
			}
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'not-found',
			redirect: '/'
		},
		{
			path: '/app',
			component: () => import('../views/app/AppView.vue'),
			meta: {
				requiresAuth: true
			},
			children: [
				{
					path: '',
					redirect: 'guides',
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'guides',
					name: 'Home',
					component: () => import('../views/app/DecksView.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'guides/:deckId',
					component: () => import('@/views/app/Guides/FreeGuide.vue'),
					name: 'FreeGuide',
					props: true,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'guides/product/:deckId',
					component: () => import('../views/app/Guides/ProductGuide.vue'),
					name: 'ProductGuide',
					props: true,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'guides/competitor/:deckId',
					component: () => import('../views/app/Guides/CompetitorGuide.vue'),
					name: 'CompetitorGuideView',
					props: true,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'settings',
					name: 'Settings',
					component: () => import('../views/app/settings/SettingsParentView.vue'),
					meta: {
						requiresAuth: true
					},
					redirect: { name: 'CompanySettings' },
					children: [
						{
							path: 'company',
							name: 'CompanySettings',
							component: () => import('../views/app/settings/CompanySettingsView.vue'),
							meta: {
								requiresAuth: true
							}
						},
						{
							path: 'tags',
							name: 'TagSettings',
							component: () => import('../views/app/settings/TagSettingsView.vue'),
							meta: {
								requiresAuth: true
							}
						}
					]
				},
				{
					path: 'usersettings',
					name: 'UserSettings',
					component: () => import('../views/app/UserSettingsView.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'products',
					name: 'Products',
					meta: {
						requiresAuth: true
					},
					redirect: { name: 'ProductsList' },
					children: [
						{
							path: '',
							name: 'ProductsOverview',
							component: () => import('@/views/app/products/ProductsParentView.vue'),
							children: [
								{
									path: '',
									name: 'ProductsList',
									component: () => import('@/views/app/products/ProductsView.vue'),
									meta: {
										requiresAuth: true
									}
								},

								{
									path: 'features',
									name: 'Features',
									component: () => import('@/views/app/products/FeaturesView.vue'),
									meta: {
										requiresAuth: true
									}
								}
							]
						},
						{
							path: ':id',
							name: 'ProductDetails',
							props: true,
							component: () => import('@/views/app/products/ProductDetailsView.vue'),
							meta: {
								requiresAuth: true
							}
						}
					]
				},

				{
					path: 'files',
					name: 'Files',
					component: () => import('../views/app/FilesView.vue'),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: 'personas',
					name: 'Personas',
					component: () => import('../views/app/personas/PersonasParentView.vue'),
					meta: {
						requiresAuth: true
					},
					redirect: { name: 'PersonaProfiles' },
					children: [
						{
							path: '',
							name: 'PersonaProfiles',
							component: () => import('../views/app/personas/PersonasView.vue'),
							meta: {
								requiresAuth: true
							}
						},
						{
							path: 'pains',
							name: 'Pains',
							component: () => import('../views/app/personas/PainsView.vue'),
							meta: {
								requiresAuth: true
							}
						}
					]
				},
				{
					path: 'meeting-genius',
					name: 'MeetingGenius',
					meta: {
						requiresAuth: true
					},
					redirect: { name: 'MeetingGeniusOverview' },
					children: [
						{
							path: '',
							name: 'MeetingGeniusParent',
							component: () => import('@/views/app/meetingGenius/MeetingGeniusParentView.vue'),
							meta: {
								requiresAuth: true
							},
							children: [
								{
									path: 'overview',
									name: 'MeetingGeniusOverview',
									component: () => import('@/views/app/meetingGenius/MeetingGeniusOverview.vue'),
									meta: {
										requiresAuth: true
									}
								},
								{
									path: 'history',
									name: 'MeetingGeniusHistory',
									component: () => import('@/views/app/meetingGenius/MeetingGeniusHistoryView.vue'),
									meta: {
										requiresAuth: true
									},
									children: []
								}
							]
						},
						{
							path: ':id',
							name: 'MeetingGeniusDetail',
							component: () => import('@/views/app/meetingGenius/MeetingGeniusDetailView.vue'),
							meta: {
								requiresAuth: true
							},
							props: true
						},
						{
							path: 'history/:id',
							name: 'MeetingGeniusHistoryDetail',
							component: () => import('@/views/app/meetingGenius/MeetingGeniusHistoryDetailView.vue'),
							meta: {
								requiresAuth: true
							},
							props: true
						}
					]
				},
				{
					path: 'competitors',
					name: 'Competitors',
					component: () => import('@/views/app/competitors/CompetitorsParentView.vue'),
					meta: {
						requiresAuth: true
					},
					redirect: { name: 'CompetitorCompanies' },
					children: [
						{
							path: '',
							name: 'CompetitorCompanies',
							component: () => import('@/views/app/competitors/CompetitorsView.vue'),
							meta: {
								requiresAuth: true
							}
						},
						{
							path: 'products',
							name: 'CompetitorProducts',
							component: () => import('@/views/app/competitors/CompetitorProductsView.vue'),
							meta: {
								requiresAuth: true
							}
						}
					]
				}
			]
		}
	]
})

router.beforeEach(async (to, from, next) => {
	let isAuthenticated = false
	try {
		await auth.authStateReady()
		if (auth.currentUser) {
			isAuthenticated = true
		}
	} catch (error) {
		console.error('Error checking authentication state:', error)
	}

	// Check if the route requires authentication
	if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
		// for pages with requiresAuth push to /login if the user is not authenticated
		next('/login')
		return
	}
	if (to.matched.some((record) => record.meta.requiresAnonymous) && isAuthenticated) {
		// for pages with requiresAnonymous push to /app/guides if the user is authenticated
		next('/app/guides')
		return
	}

	next()
})

export default router
